body {
  font-size: 14px;
  font-family: Open Sans,PingFang SC,Microsoft YaHei,Helvetica Neue,Hiragino Sans GB,WenQuanYi Micro Hei,Arial,"sans-serif";
  // 隐藏滚动条
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}

body, ul, h1, h2, h3, h4, h5, h6, p, figure {
  margin: 0;
  padding: 0;
}

ul, li {
  list-style: none;
}

a:focus,
a:active {
  outline: none;
}

a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}